<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input">
          <el-col :span="6">
            <el-form-item label="采购单号" :class="$i18n.locale">
              <el-input v-model="form.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="7">
            <el-form-item label="供应商" prop="vendorId" :class="$i18n.locale">
              <el-select v-model="form.vendorId" clearable filterable :placeholder="$t('page.selectPlaceholder')" multiple>
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="创建时间" :class="$i18n.locale">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <!-- <ImportButton export-key="" :params="ExportParams" /> -->
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" @click="handleQuery()">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="mt-2  mb-3">
      <el-button v-permission="'request'" type="primary" :loading="triggerAutoRequestFundsLoading" @click="triggerAutoRequestFunds">定时请款任务触发</el-button>
      <el-button v-permission="'request'" type="primary" :loading="triggerAutoSendReconcileMailLoading" @click="triggerAutoSendReconcileMail">定时发邮件任务触发</el-button>
      <el-button v-permission="'request'" type="primary" :loading="triggerAutoSettlementLoading" @click="triggerAutoSettlement">定时结算任务触发</el-button>
    </el-row>
    <el-row class="select-info my-2 px-2" :class="quantityStyle" type="flex" justify="space-between">
      <el-col :span="12">
        <i class="el-icon-info  i" />
        <span class="ml-2 i">{{ $t('page.alreadySel') }} {{ quantity }} 项</span>
        <el-button :disabled="quantity === 0" type="text" class="ml-2" @click="clearSelection">清空</el-button>
      </el-col>
      <el-col :span="12">
        <el-input v-model="rate" style="width:80px;" oninput="value=value.replace(/[^0-9.]/g,'')" /> 比例(%) <el-button size="mini" type="primary" class="ml-3" @click="handleTable">一键赋值</el-button> <el-button type="primary" size="mini" @click="checkorder">复核</el-button>
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="500px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="50"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchaseOrderCode"
        label="采购订单号"
        align="center"

        min-width="115"
      />
      <el-table-column
        prop="contractCode"
        label="合同号"
        width="100"
        align="center"
      />

      <el-table-column
        prop="orderStatusI18"
        label="订单状态"
        width="130"
        align="center"
      />
      <el-table-column
        prop="qualityStatusI18"
        label="质检状态"
        width="90"
        align="center"
      />
      <el-table-column
        prop="vendorName"
        label="供应商"
        width="130"
        align="center"
      />
      <el-table-column
        prop="applyDeposit"
        label="申请定金"
        width="100"
        align="center"
      />
      <el-table-column
        label="审核定金"
        width="140"
        align="center"
      >
        <template slot-scope="scope">
          <el-input v-model="scope.row.approvalDeposit" oninput="value=value.replace(/[^0-9.]/g,'')" :disabled="Boolean(rate)" @input="input(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column
        prop="cargoOwnerName"
        label="货主"
        width="130"
        align="center"
      />
      <el-table-column
        prop="cargoOwnerName"
        label="订单数量"
        width="130"
        align="center"
      />
      <el-table-column
        prop="totalPrice"
        label="订单金额"
        width="130"
        align="center"
      />
      <el-table-column
        prop="createByName"
        label="创建人"
        width="130"
        align="center"
      />
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="130"
        align="center"
      />
      <el-table-column
        prop="vendorDeliveryFeedback"
        label="供应商交期反馈"
        width="130"
        align="center"
      />
      <el-table-column
        prop="vendorDeliveryFeedbackRemark"
        label="备注"
        width="100"
        align="center"
      />
    </el-table>
    <Paging :pager="pager" end @pagination="pagerUpdate" />

  </div>
</template>

<script>
import { queryVendorList } from '@/api/scm-api'
import { depositPage, batchApprovalDeposit } from '@/api/deposit-api'
import Paging from '@/components/Pagination'
import { triggerAutoRequestFunds, triggerAutoSettlement, triggerAutoSendReconcileMail } from '@/api/vendorPurchase-api.js'
export default {
  components: { Paging },

  data() {
    return {
      triggerAutoRequestFundsLoading: false,
      triggerAutoSendReconcileMailLoading: false,
      triggerAutoSettlementLoading: false,
      rate: '',
      vendorOptions: [],
      form: {
        value1: []
      },
      quantity: 0,
      tableDatas: [],
      multipleSelection: [],
      TableLoading: false,
      pager: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  computed: {
    quantityStyle() {
      return {
        info: this.quantity === 0,
        primary: this.quantity > 0
      }
    },
    queryClickParams() {
      const { purchaseOrderCode, vendorId, value1 } = this.form
      const [startCreateTime, endCreateTime] = value1 || []
      return Object.assign({}, this.pager, { startCreateTime, endCreateTime, purchaseOrderCode, vendorId: vendorId ? vendorId.toString() : '' })
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    this.handleQuery()
  },
  methods: {
    async triggerAutoSendReconcileMail() {
      try {
        this.triggerAutoSendReconcileMailLoading = true
        const { code, msg } = await triggerAutoSendReconcileMail()
        if (code === 0) {
          this.$message.success(msg)
        }
      } finally {
        this.triggerAutoSendReconcileMailLoading = false
      }
    },
    async triggerAutoSettlement() {
      try {
        this.triggerAutoSettlementLoading = true
        const { code, msg } = await triggerAutoSettlement()
        if (code === 0) {
          this.$message.success(msg)
        }
      } finally {
        this.triggerAutoSettlementLoading = false
      }
    },
    async triggerAutoRequestFunds() {
      try {
        this.triggerAutoRequestFundsLoading = true
        const { code, msg } = await triggerAutoRequestFunds()
        if (code === 0) {
          this.$message.success(msg)
        }
      } finally {
        this.triggerAutoRequestFundsLoading = false
      }
    },
    handleQuery() {
      this.pager.current = 1
      this.queryClick()
    },
    checkorder() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return false
      }
      this.$confirm('确定要复核定金吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        let arr = JSON.parse(JSON.stringify(this.multipleSelection))
        arr = arr.map(item => ({ purchaseOrderCode: item.purchaseOrderCode, approvalDeposit: item.approvalDeposit }))
        const { code } = await batchApprovalDeposit(arr)
        if (code === 0) {
          this.$message.success('操作成功') && this.handleQuery()
          this.rate = ''
        }
      })
    },
    handleTable() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
        return false
      }
      if (this.rate) {
        this.multipleSelection.map(item => {
          this.$set(item, 'approvalDeposit', Number(this.rate * item.totalPrice / 100).toFixed(2))
        })
      } else {
        const approvalDeposit = this.multipleSelection[0].approvalDeposit
        this.multipleSelection.map(item => { item.approvalDeposit = approvalDeposit })
      }
      // console.log(this.multipleSelection)
    },
    input(row) {
      row.approvalDeposit > 0 ? this.$refs.multipleTable.toggleRowSelection(row, true)
        : this.$refs.multipleTable.toggleRowSelection(row, false)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.quantity = this.multipleSelection.length
    },
    clearSelection() {
      this.multipleSelection = []
      this.$refs.multipleTable.clearSelection()
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async queryClick() {
      try {
        this.TableLoading = true
        const { datas } = await depositPage(this.queryClickParams)
        const { records, pager } = datas
        this.tableDatas = records
        const { current, size, total } = pager
        this.pager = { current, size, total }
        this.TableLoading = false
      } finally {
        this.TableLoading = false
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.handleQuery()
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    }
  }
}
</script>

<style scoped lang="scss">
.select-info {
  border-radius: 6px;
}
.primary {
  background-color:#e6f7ff ;
  border: 1px solid #bae7ff;
  .el-icon-info {
    color: #1890ff;
  }
}
.info {
  background-color:#f4f4f5 ;
  border: 1px solid #dcdcdc;
  .i {
    color: #909399;
  }
}
</style>
